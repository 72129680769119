export default {
  data() {
    return {
      timer: undefined,
      helpContainer: null
    }
  },
  methods: {
    showHelpModal() {
      if (!this.helpContainer) {
        this.$store.commit('SET_GLOBAL_LOADING', true)
      }
      const LiveChatSRC = require('../plugins/live-chat.js')
      if (typeof LiveChatWidget != 'undefined') {
        LiveChatWidget.call('maximize')
      } else {
        let LiveChat = document.createElement('script')
        LiveChat.setAttribute('src', LiveChatSRC)
        LiveChatWidget.call('maximize')
      }

      this.timer = setInterval(() => {
        this.checkHelpModal()
      }, 500)
    },
    checkHelpModal() {
      this.helpContainer = document.getElementById('chat-widget-container')
      if (this.helpContainer) {
        this.$store.commit('SET_GLOBAL_LOADING', false)
        clearInterval(this.timer)
      }
    },
  },
}
