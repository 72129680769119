export default {
  methods: {
    addMobileParameter(params) {
      let _params = {...params}
      const _query = {...this.$route.query}
      const displayedPrice = _query['google-displayed-price']
      const rateRuleId = _query['rate_rule_id']
      const isMobileDevice = checkIsMobileByUserAgent()

      if (displayedPrice && rateRuleId || isMobileDevice) {
        _params['is_mobile'] = true
      }

      return _params
      function checkIsMobileByUserAgent() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      }
    }
  }
}
