import axios from 'axios'

export default async function ({ route, redirect }) {
  let url = '/'

  if (route.query.name !== '') {
    let baseURL = 'https://dev.tripening.com/api/'

    if (process.env.NODE_ENV === 'production') {
      baseURL = '/api/'
    }

    let res = await axios.get(`${baseURL}search/v1/geo-places?name=${route.query.name}`)
    if (res.data.length === 1) {
      url = route.path
      url += res.data[0].slug

      if (route.query.start && route.query.start !== '') {
        url += '?start=' + route.query.start
        if (route.query.end && route.query.end !== '') url += '&end=' + route.query.end
      }
      if (route.query.adult !== '' && +route.query.adult > 0 ) url += '&adult=' + route.query.adult
      if (route.query.child !== '' && +route.query.child > 0) url += '&child=' + route.query.child
      if (route.query.youth !== '' && +route.query.youth > 0) url += '&youth=' + route.query.youth
      if (route.query.senior !== '' && +route.query.senior > 0) url += '&senior=' + route.query.senior
    }
  }

  return redirect(url)
}
