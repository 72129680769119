
export const currencies = [
  {
    sign: '$',
    name: 'United States dollar',
    code: 'USD'
  },
  {
    sign: '€',
    name: 'Euro',
    code: 'EUR'
  },
  {
    sign: '£',
    name: 'Pound sterling',
    code: 'GBP'
  },
  {
    sign: '$',
    name: 'Canadian dollar',
    code: 'CAD'
  },
  {
    sign: '￥',
    name: 'Chinese yuan',
    code: 'CNY'
  },
  {
    sign: '$',
    name: 'Australian dollar',
    code: 'AUD'
  }
]


export default {
  methods: {
    async selectCurrency(currentCurrency){
      if (!currentCurrency) return
      const query = Object.assign({}, this.$route.query)
      query.currency = currentCurrency.code
      await this.$router.push({query})
      this.$store.commit('SET_CURRENT_CURRENCY', currentCurrency)
    },
    async selectCurrencySelector(currentCurrency){
      if (!currentCurrency) return
      const query = Object.assign({}, this.$route.query)
      query.currency = currentCurrency.code
      this.$store.commit('SET_CURRENT_CURRENCY', currentCurrency)
      await this.$router.replace({query: {...this.$route.query, currency: currentCurrency.code}})

      if (process.client){
        window.location.reload()
      }
    },
    openMulticurrencyPopup() {
      this.$store.commit('SET_MUTICURRENCY_POPUP_OPEN', true)
    },
    findCurrencySignByCode(code) {
      if (!code) return

      const currentCurrency = this.findCurrencyByCode(code)
      return currentCurrency.sign
    },
    findCurrencyByCode(code) {
      if (!code) return

      return this.availableCurrencies.find(currencyObj => code === currencyObj.code)
    },
  },
  computed: {
    availableCurrencies() {
      return currencies
    },
    selectedCurrency() {
      return this.$store.state.currency
    },
    currencySign() {
      return this.selectedCurrency.sign
    }
  }
}


