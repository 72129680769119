<template>
  <div>
    <nuxt/>
  </div>
</template>


<script>
const faviconURls = {
  tripening: '/tripening.ico',
  hnm: '/hnm.png',
}

const titles = {
  tripening: `Things to do, Tickets, Hotels and Flights | ${new Date().getFullYear()} | Tripening`,
  hnm: `Hotels | ${new Date().getFullYear()} | HotelsNearMe`,
}


export default {
  head() {
    return {
      title: titles[this.$store.state.siteBrand],
      link: [
        {rel: 'icon', type: 'image/x-icon', href: `${this.$config.baseURL}${faviconURls[this.$config.brand]}`},
      ]
    }
  },
}
</script>
