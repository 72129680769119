const Formats = { FULL: 'full', SM: 'sm', XS: 'xs' }
const Weekdays = {
  full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  sm: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  xs: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
}

export default {
  methods: {
    formatDate (date, isReadable = false, showYear = true) {
      if (!date) return null

      return this.$moment(date).format(isReadable ? (showYear ? 'll' : 'MMM Do') : 'MM/DD/YYYY')
    },
    formatSearchPreviewDate(date, short= false) {
      if (!date) return null

      let _formattedDate = this.$moment(date).format('DD MMMM')
      if (short) _formattedDate = this.$moment(date).format('MMM DD')

      return _formattedDate
    },
    formatSameMonthDate(firstDate, lastDate) {
      if (!firstDate || !lastDate) return null

      const firstDateMonth = this.$moment(firstDate).format('MMMM')
      const lastDateMonth = this.$moment(lastDate).format('MMMM')

      return firstDateMonth === lastDateMonth ? this.$moment(firstDate).format('DD') + '-' + this.$moment(lastDate).format('DD MMMM')  : this.$moment(firstDate).format('DD MMMM')
    },
    formatDateWithWeekDay(date) {
      if (!date) return null

      return this.$moment(date).format('ddd Do MMMM YYYY')
    },
    formatTime(date, time) {
      if (!date && !time) return null

      return this.$moment(date + ' ' + time).format('LT')
    },
    formatTimeFromFullDate(fullDate) {
      if (!fullDate) return null

      return this.$moment(fullDate).format('LT')
    },
    getDay(date, full = false) {
      return this.getDayBySize(date, full ? this.weekdaysFormats.FULL : this.weekdaysFormats.SM)
    },
    getDayForCalendar(date) {
      return this.getDayBySize(date, this.weekdaysFormats.XS)
    },
    getDayBySize(date, size = this.weekdaysFormats.FULL) {
      const daysOfWeek = this.weekdays[size]
      const day = new Date(date).getUTCDay()
      return daysOfWeek[day]
    },
    makeMonthFormat(monthNumber) {
      let formattedMonthNubmer = monthNumber

      if (monthNumber < 10){
        formattedMonthNubmer = '0'+ monthNumber
      }

      return formattedMonthNubmer
    },
    countDaysPeriod(fromDateStr, toDateStr) {
      if (!fromDateStr || !toDateStr) return null

      const start = new Date(fromDateStr)
      const end = new Date(toDateStr)
      const days = (end - start)/(1000 * 3600 * 24)

      return days
    },
    countTimePeriod(fromDateStr, toDateStr) {
      const start = new Date(fromDateStr)
      const end = new Date(toDateStr)

      return this.countReadableHours((end - start)/60000)
    },
    countReadableHours(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60

      return `${hours ? hours + 'h ' : ''} ${minutes ? minutes + 'm' : ''}`
    },
    duration(durationStr) {
      const result = this.$moment.duration(durationStr).humanize()
      return result
    }
  },
  computed: {
    weekdaysFormats() {
      return Formats
    },
    weekdays() {
      return Weekdays
    },
  }
}
