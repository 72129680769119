export default {
  data() {
    return {
      signInDialog: false,
      signUpDialog: false,
    }
  },
  methods: {
    closeSignIn() {

      if (document?.body?.classList?.contains('no-mobile-scroll-fh')) {
        document?.body?.classList?.remove('no-mobile-scroll-fh')
        this.$store.commit('SET_CONTACT_FORM_IN_CART_OPEN', false)
        this.signInDialog = false
      }
    },
    closeSignUp() {
      if (document?.body?.classList?.contains('no-mobile-scroll-fh')) {
        document.body.classList?.remove('no-mobile-scroll-fh')
        this.$store.commit('SET_CONTACT_FORM_IN_CART_OPEN', false)
        this.signUpDialog = false
      }
    },
    openSignIn() {
      this.closeSignUp()
      this.$store.commit('SET_CONTACT_FORM_IN_CART_OPEN', true)
      this.signInDialog = true
      document.body.classList.add('no-mobile-scroll-fh')
    },
    openSignUp() {
      this.closeSignIn()
      this.$store.commit('SET_CONTACT_FORM_IN_CART_OPEN', true)
      this.signUpDialog = true
      document.body.classList?.add('no-mobile-scroll-fh')
    },
    hideSignDialogs() {
      this.closeSignIn()
      this.closeSignUp()
    },
  },
  computed: {
    isOpen(){
      return this.$store.getters.getContactFormInCartOpen
    },
  },
  watch: {
    isOpen(val){
      if (val){
        this.openSignIn()
      } else {
        if (this.signInDialog){
          this.closeSignIn()
        } else if (this.signUpDialog){
          this.closeSignUp()
        }
      }
    }
  }
}
