<template>
  <div>
    <v-app>
      <site-header/>
      <nuxt/>
    </v-app>
  </div>
</template>

<script>
import SiteHeader from '@/components/NewHeader/Header'
export default {
  components: {
    SiteHeader
  }
}
</script>

