
const _INVALID_CODE_ERROR = {
  code: 2400,
  detail: 'The promotional code you entered is not valid.'
}

const INTERNAL_SERVER_ERROR = {
  code: 500,
  detail: 'Internal server error.'
}

const GENERAL_ERROR = {
  code: 1001,
  detail: 'An error occurred while applying the discount.'
}

export default {
  data() {
    return {
      couponCode: '',
      errorMessages: [],
      errorText: 'Conditions for coupon code were not met',
      isBtnActive: false
    }
  },
  methods: {
    onCouponCodeChange() {
      this.changedCouponCode()
    },
    changedCouponCode() {
      this.errorMessages = []
      this.isBtnActive = this.couponCode.length > 0
    },
    handleDiscountErrors(error) {
      if (error?.response?.data?.errors) {
        const { errors } = error.response.data

        errors.forEach(err => {
          switch (err.code) {
            case 2400:
              this.errorMessages.push(_INVALID_CODE_ERROR)
              break
            default:
              this.errorMessages.push(err)
          }
        })
      } else if (error?.response?.status === 500) {
        this.errorMessages.push(INTERNAL_SERVER_ERROR)
      } else {
        this.errorMessages.push(GENERAL_ERROR)
      }
    },
  },
  computed: {
    currentCurrencySign() {
      return this.$store.state.currency.sign
    },
    currentCurrencyCode() {
      return this.$store.state.currency.code
    },
    hasError() {
      return this.errorMessages.length > 0
    }
  }
}
